.App {
  text-align: center;
  /* background-color: #1890ff; */
  width: 100vw;
}

.App-logo {
  height: 40vmin;
  margin: auto;
  pointer-events: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.onTheWay {
  width: 50%;
}
.app-logo {
  width: 5vw;
}

.app-logo-text {
  color: white;
  font-size: calc(10px +2vmin);
  font-weight: bold;
  font: "PingFang SC";
}

.cardFull::before,
.cardFull::after {
  height: 0px !important;
}
/* 
  X-Small devices (portrait phones, less than 576px) 
 No media query for `xs` since this is the default in Bootstrap
*/
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .onTheWay {
    width: 20%;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .onTheWay {
    width: 20%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .onTheWay {
    width: 20%;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .onTheWay {
    width: 20%;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .onTheWay {
    width: 20%;
  }
}

.headerHeader {
  text-align: left;
  color: #666666;
  font-size: 13px;
}

.headerText {
  text-align: left;
  font-weight: bold;
}

.proofOfDelivery {
  text-align: left;
  font-weight: bold;
  margin-bottom: 10px;
}

.statusUpdates {
  text-align: left;
  margin-bottom: 20;
  /* color: #666666; */
  font-size: 13px;
  font-weight: bold;
}

.title {
  text-align: left;
  margin-bottom: 20;
  /* color: #666666; */
  font-size: 14px;
  color: black;
  font-weight: bold;
}

.trackingNumber {
  /* padding: 0px !important; */
  /* margin-top: -10px !important; */
  font-size: 12px;
  display: inline-block;
  width: 100%;
}

.step {
  text-align: left;
}

.steps {
  text-align: left;
  float: left;
  margin-left: -16px;
}

.stepsContainer {
  overflow: auto;
}

.stepTitle {
  font-size: 12px;
}

.stepDescription {
  font-size: 12px;
  margin-top: -5px;
}
.stepIcon {
  margin-top: calc(var(--title-font-size) * 1 / 2);
}

.merchantHeaderContainer {
  display: flex;
  align-items: center;
}

.desktopMerchantHeaderIcon {
  vertical-align: middle;
  margin-right: 5px !important;
  padding-left: 0px;
}

.desktopDriverIcon {
  vertical-align: left;
  margin-right: 0px;
  margin-left: 10px;
}

.merchantHeaderName {
  /* vertical-align:middle; 
  padding-left: 10px;
  font-weight: bold;
  font-size: 1.2em; */
  text-align: left;
  font-size: 20;
  font-weight: bold;
  vertical-align: middle;
  display: inline-block;
}

.carbonNeutralHeader {
  vertical-align: middle;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  /* font-weight: bold; */
  color: #1890ff;
  font-size: 0.9em;
  /* margin-top: 5px !important; */
}

.trackingNo {
  text-align: left;
  vertical-align: left;
  padding-left: 10px;
  padding-right: 10px;
  /* font-weight: bold; */
  /* color: #1890ff; */
  margin-top: 5px !important;
}

.merchantHeaderDivider {
  margin-top: 5px !important;
}

.merchantFooterDivider {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.merchantHeaderTagContainer {
  margin-left: auto;
  margin-right: 0px;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  text-align: center;
}

.bodyContainer {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 48vh;
  margin-bottom: 2vh;
}

.buttonContentBodyContainer {
  padding-left: 10px;
  padding-right: 10px;
}

.footerContainer {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
}

.activeButtonContainer {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
}

.orderInfoSubcomponentContainer {
  /* height: 100vh; */
  /* background-color: white; */
  padding-top: 1.2vh;
}

.orderInfoContainer {
  height: 100vh;
  /* background-color: white; */
}

body {
  position: fixed;
  overflow: hidden;
}

.navbarTitle {
  color: #1890ff;
  font-weight: bold;
  font-family: "PingFang SC";
}

.tag {
  font-weight: bold;
  color: #1890ff;
}
.tagOutForDelivery {
  font-weight: bold;
  color: orange;
}

.navbar {
  color: #1890ff;
}

.adm-dialog-action-row {
  border-top: 0px solid var(--adm-border-color) !important;
}

.adm-dialog-body-title {
  font-weight: normal !important;
}
.adm-dialog-body-title {
  color: #000000 !important;
}

.adm-dialog-body-message input {
  color: #000000 !important;
}

.adm-dialog-body-message input ::placeholder {
  color: #000000 !important;
}

.adm-dialog-body-message {
  font-size: 15px !important;
  text-align: center !important;
  color: var(--adm-color-weak) !important;
}

.adm-dialog-button {
  border-right: solid 0px var(--adm-border-color) !important;
}

.adm-button:not(.adm-button-default).adm-button-fill-none {
  --text-color: #1890ff !important;
  /* --background-color: #000d1a !important; */
}

.wrapper > .adm-dropdown {
  background-color: red !important;
}

.dropDownWrapper > .adm-dropdown {
  float: right;
  justify-content: right;
}

.cancelButton {
  color: black;
}

/* .adm-nav-bar {
  padding: 0px 0px !important;
} */

.adm-dialog-body-message-wrapper {
  display: block !important;
  justify-content: center !important;
}

.footerInfoContainer {
  display: flex;
  align-items: center;
}

/* .container .block {
  padding-left: 10px;
  padding-right: 10px;
} */
.container {
  height: 100vh;
  /*
  background-color: #f0f2f5;
  */
  background-position: left top, 50% 90%;
  background-image: none;
  background-repeat: no-repeat;
  background-size: contain;
  overflow-y: hidden;
  overflow-x: hidden;
}
.adm-step-description {
  line-height: 0.4 !important;
}

.headerInfoContainer {
  padding: 100;
  display: flex;
  align-items: center;
}

.navbarContainer {
  width: 100%;
  text-align: center;
  padding: 10px;
}

.navbarLeft {
  float: left;
  width: 100px;
}

.navbarCenter {
  display: inline-block;
  margin: 0 auto;
  width: 100px;
}

.navbarRight {
  float: right;
  width: 100px;
}

/*desktop*/
.desktopFooterContainer {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  position: fixed;
  bottom: 0;
}

.desktopBodyContainer {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 60vh;
  min-height: 60vh;
  margin-bottom: 2vh;
}

.desktopHeaderHeader {
  text-align: left;
  color: #666666;
  font-size: 13px;
}

.desktopHeaderText {
  text-align: left;
  font-weight: bold;
  margin-bottom: 0 !important;
}

.desktopStatusUpdates {
  text-align: center;
  margin-bottom: 20;
  color: #666666;
  font-size: 13px;
}

.desktopSteps {
  text-align: left;
  float: left;
  margin-left: -16px;
}

.desktopProofOfDelivery {
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}

.desktopStepsContainer {
  overflow: auto;
}

.desktopMerchantHeaderContainer {
  display: flex;
  align-items: center;
}

.desktopSteps {
  text-align: left;
  float: left;
  margin-left: -16px;
  width: 100%;
}

.ant-card {
  border-radius: 8px !important;
}

.merchantHeaderIcon {
  vertical-align: middle;
  margin-right: 5px !important;
  margin-left: 10px !important;
}

.infoText {
  text-align: left;
  /* color: #666666; */
  font-size: 12px;
  font-weight: normal;
}

.infoHeader {
  text-align: left;
  margin-bottom: 20;
  /* color: #666666; */
  font-size: 14px;
  font-weight: bold;
}

#map_canvas img {
  margin: 0px;
}

a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}
a[href^="https://maps.google.com/maps"]
{
  display: none !important;
}

.gmnoprint a,
.gmnoprint span,
.gm-style-cc {
  display: none;
}
.gmnoprint div {
  background: none !important;
}

.adm-steps-vertical .adm-step .adm-step-indicator .adm-step-icon-container {
  background-color: rgba(0, 0, 0, 0);
}

.ant-switch {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.ant-switch-checked {
  background-color: #1890ff !important;
}


/* .dialogFooterDark {
  --background-color: #000d1a !important;
} */